<template>
  <modal
    name="detail-select"
    height="100%"
    classes="modal-wrapper"
    scrollable
    style="z-index: 1050; display: flex; justify-content: center"
  >
    <div class="modal-search-filter">
      <div class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">
          家タイプ
          <a href="https://sites.google.com/address.love/popup/guide/housetype" target="_blank">
            <icon-info></icon-info>
          </a>
        </p>
        <div class="modal-search-filter-horizontal">
          <div v-for="kind in homeKinds" :key="'home-kind-' + kind.value">
            <input
              :id="'home_kind_' + kind.value"
              type="checkbox"
              :checked="checkedHomeKinds.includes(kind.value)"
              @change="homeKindsChanged($event, kind.value)"
            />
            <label :for="'home_kind_' + kind.value">{{ kind.name }}</label>
          </div>
        </div>
      </div>
      <div class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">部屋タイプ</p>
        <div class="modal-search-filter-horizontal">
          <div v-for="kind in roomKinds" :key="'room-kind-' + kind.value">
            <input
              :id="'room_kind_' + kind.value"
              type="checkbox"
              :checked="checkedRoomKinds.includes(kind.value)"
              @change="roomKindsChanged($event, kind.value)"
            />
            <label :for="'room_kind_' + kind.value">{{ kind.name }}</label>
          </div>
        </div>
      </div>
      <div class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">家/施設</p>
        <div class="modal-search-filter-vertical">
          <div>
            <input id="is_original" type="checkbox" :checked="isOriginal" @change="checkChanged($event, 'isOriginal')" />
            <label for="is_original">
              <img :src="iconOriginalImgSrc" class="modal-search-filter-originalicon" />
              ADDressオリジナル
              <a href="https://sites.google.com/address.love/popup/guide/original" target="_blank">
                <icon-info></icon-info>
              </a>
            </label>
          </div>
          <div>
            <input id="has_kitchen" type="checkbox" :checked="hasKitchen" @change="checkChanged($event, 'hasKitchen')" />
            <label for="has_kitchen">キッチンあり</label>
          </div>
          <div class="modal-search-filter-parking">
            <input id="has_parking" type="checkbox" :checked="hasParking" @change="hasParkingChanged" />
            <label for="has_parking">駐車場あり</label>
            <p>（</p>
            <div style="margin: 0 8px 0 2px">
              <input id="is_parking_free" type="checkbox" :checked="isParkingFree" @change="parkingDetailChanged" />
              <label for="is_parking_free">無料</label>
            </div>
            <div>
              <input id="is_parking_reservable" type="checkbox" :checked="isParkingReservable" @change="parkingDetailChanged" />
              <label for="is_parking_reservable">事前予約可</label>
            </div>
            <p>）</p>
          </div>
          <div>
            <input id="has_desk_chair" type="checkbox" :checked="hasDeskChair" @change="checkChanged($event, 'hasDeskChair')" />
            <label for="has_desk_chair">部屋にデスクチェアあり</label>
          </div>
        </div>
      </div>
      <div class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">交流</p>
        <div class="modal-search-filter-vertical">
          <div>
            <input
              id="can_meet_yamori"
              type="checkbox"
              :checked="canMeetYamori"
              @change="checkChanged($event, 'canMeetYamori')"
            />
            <label for="can_meet_yamori">
              家守に会える
              <a href="https://sites.google.com/address.love/popup/guide/yamori" target="_blank">
                <icon-info></icon-info>
              </a>
            </label>
          </div>
          <div>
            <input id="is_member_only" type="checkbox" :checked="isMemberOnly" @change="checkChanged($event, 'isMemberOnly')" />
            <label for="is_member_only">
              ADDress会員だけが利用できる
              <a href="https://sites.google.com/address.love/popup/guide/exclusive" target="_blank">
                <icon-info></icon-info>
              </a>
            </label>
          </div>
        </div>
      </div>
      <div v-if="isLegacyPlan" class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">
          予約制限
          <a href="https://sites.google.com/address.love/popup/guide/housegroup" target="_blank">
            <icon-info></icon-info>
          </a>
        </p>
        <div class="modal-search-filter-horizontal">
          <div v-for="kind in homeGroupKinds" :key="'home-group-kind-' + kind.value">
            <input
              :id="'home_group_kind_' + kind.value"
              type="checkbox"
              :checked="checkedHomeGroupKinds.includes(kind.value)"
              @change="homeGroupKindsChanged($event, kind.value)"
            />
            <label :for="'home_group_kind_' + kind.value">{{ kind.name }}</label>
          </div>
        </div>
      </div>
      <div class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">予約オプション</p>
        <div>
          <div>
            <input
              id="has_auto_approval"
              type="checkbox"
              :checked="hasAutoApproval"
              @change="checkChanged($event, 'hasAutoApproval')"
            />
            <label for="has_auto_approval">
              家守の予約承認が不要な家
              <a href="https://address-membersupport.zendesk.com/hc/ja/articles/18881530606361" target="_blank">
                <icon-info></icon-info>
              </a>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">割引き</p>
        <div>
          <div class="modal-search-filter-renpaku">
            <input id="has_discount" type="checkbox" :checked="hasDiscount" @change="hasDiscountChanged" />
            <label for="has_discount">
              連泊割
              <a href="https://address-membersupport.zendesk.com/hc/ja/articles/24627105066393" target="_blank">
                <icon-info></icon-info>
              </a>
            </label>
            <p>（</p>
            <div style="margin: 0 8px 0 2px">
              <input
                id="has_discount_three_nights"
                type="checkbox"
                :checked="hasDiscountThreeNights"
                @change="discountDetailChanged"
              />
              <label for="has_discount_three_nights">3泊割</label>
            </div>
            <div>
              <input
                id="has_discount_seven_nights"
                type="checkbox"
                :checked="hasDiscountSevenNights"
                @change="discountDetailChanged"
              />
              <label for="has_discount_seven_nights">7泊割</label>
            </div>
            <p>）</p>
          </div>
        </div>
      </div>
      <div v-if="!isUnregistered" class="modal-search-filter-box">
        <p class="modal-search-filter-ttl">表示条件</p>
        <div>
          <div>
            <input id="favorite_only" type="checkbox" :checked="favoriteOnly" @change="checkChanged($event, 'favoriteOnly')" />
            <label for="favorite_only">お気に入りに登録した家のみ表示</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-fixbottom">
      <button class="modal-clearbtn" @click="clearDetail">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#adadad"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="inline-block mb-1"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
        選択をクリア
      </button>
      <button class="modal-primarybtn" @click.prevent="$modal.hide('detail-select')">決定</button>
    </div>
  </modal>
</template>

<script>
import IconInfo from '../../icons/Info.vue'

export default {
  components: {
    IconInfo,
  },
  props: {
    homeKinds: {
      type: Array,
      required: true,
    },
    roomKinds: {
      type: Array,
      required: true,
    },
    homeGroupKinds: {
      type: Array,
      required: true,
    },
    checkedHomeKinds: {
      type: Array,
      required: true,
    },
    checkedRoomKinds: {
      type: Array,
      required: true,
    },
    isOriginal: {
      type: Boolean,
      required: true,
    },
    hasKitchen: {
      type: Boolean,
      required: true,
    },
    hasParking: {
      type: Boolean,
      required: true,
    },
    isParkingFree: {
      type: Boolean,
      required: true,
    },
    isParkingReservable: {
      type: Boolean,
      required: true,
    },
    hasDeskChair: {
      type: Boolean,
      required: true,
    },
    canMeetYamori: {
      type: Boolean,
      required: true,
    },
    isMemberOnly: {
      type: Boolean,
      required: true,
    },
    checkedHomeGroupKinds: {
      type: Array,
      required: true,
    },
    hasAutoApproval: {
      type: Boolean,
      required: true,
    },
    hasDiscount: {
      type: Boolean,
      required: true,
    },
    hasDiscountThreeNights: {
      type: Boolean,
      required: true,
    },
    hasDiscountSevenNights: {
      type: Boolean,
      required: true,
    },
    favoriteOnly: {
      type: Boolean,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
    iconOriginalImgSrc: {
      type: String,
      required: true,
    },
    isUnregistered: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    checkChanged(event, value) {
      this.$emit('checkChanged', value, event.target.checked)
    },
    homeKindsChanged(event, value) {
      this.$emit('homeKindsChanged', event.target.checked, value)
    },
    roomKindsChanged(event, value) {
      this.$emit('roomKindsChanged', event.target.checked, value)
    },
    hasParkingChanged(event) {
      this.$emit('hasParkingChanged', event.target.checked)
    },
    parkingDetailChanged(event) {
      this.$emit('parkingDetailChanged', event)
    },
    homeGroupKindsChanged(event, value) {
      this.$emit('homeGroupKindsChanged', event.target.checked, value)
    },
    hasDiscountChanged(event) {
      this.$emit('hasDiscountChanged', event.target.checked)
    },
    discountDetailChanged(event) {
      this.$emit('discountDetailChanged', event)
    },
    clearDetail() {
      this.$emit('clearDetail')
    },
  },
}
</script>
