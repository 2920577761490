<script>
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  props: {
    sessionParams: {
      type: Object,
      required: true,
    },
    calendarStartDate: {
      type: String,
      required: true,
    },
    calendarEndDate: {
      type: String,
      required: true,
    },
    currentTime: {
      type: String,
      required: true,
    },
    areas: {
      type: Array,
      required: true,
    },
    homeKinds: {
      type: Array,
      required: true,
    },
    roomKinds: {
      type: Array,
      required: true,
    },
    homeGroupKinds: {
      type: Array,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
    iconOriginalImgSrc: {
      type: String,
      required: true,
    },
    isUnregistered: {
      type: Boolean,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    themeList: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      checkInDate: this.sessionParams.check_in_date,
      checkOutDate: this.sessionParams.check_out_date,
      adults: this.sessionParams.adult || 1,
      children: this.sessionParams.child || 0,
      preschoolers: this.sessionParams.preschooler || 0,
      rooms: this.sessionParams.room_count || 1,
      checkedAreas: [],
      checkedPrefs: [],
      checkedHomeKinds: this.sessionParams.kinds || [],
      checkedRoomKinds: this.sessionParams.room_kinds || [],
      isOriginal: this.sessionParams.is_original || false,
      hasKitchen: this.sessionParams.has_kitchen || false,
      hasParking: this.sessionParams.parking || false,
      isParkingFree: this.sessionParams.parking_free || false,
      isParkingReservable: this.sessionParams.parking_prebooking || false,
      hasDeskChair: this.sessionParams.has_desk_chair || false,
      canMeetYamori: this.sessionParams.yamori_interaction || false,
      isMemberOnly: this.sessionParams.is_member_only || false,
      checkedHomeGroupKinds: this.sessionParams.group_kinds || [],
      hasAutoApproval: this.sessionParams.is_auto_approved || false,
      hasDiscount: this.sessionParams.discount || false,
      hasDiscountThreeNights: this.sessionParams.discount_3_nights || false,
      hasDiscountSevenNights: this.sessionParams.discount_7_nights || false,
      favoriteOnly: this.sessionParams.only_favorite || false,
      checkedTag: this.sessionParams.tags ? Number(this.sessionParams.tags[0]) : null, // 現状だと選択は単数という前提
      sortedTags: [],
      checkedThemeList: this.themeList,
    }
  },
  computed: {
    datesLabel() {
      if (this.checkInDate && this.checkOutDate) {
        return `${dayjs(this.checkInDate).format('M/D')}〜${dayjs(this.checkOutDate).format('M/D')}`
      } else {
        return null
      }
    },
    capacityLabel() {
      let label = []
      if (this.adults > 0) {
        label.push(`大人${this.adults}名`)
      }
      if (this.children > 0) {
        label.push(`子ども${this.children}名`)
      }
      if (this.preschoolers > 0) {
        label.push(`幼児${this.preschoolers}名`)
      }
      if (!this.isLegacyPlan) {
        if (this.rooms > 0) {
          label.push(`${this.rooms}室`)
        }
      }
      return label.join(' ')
    },
    prefectures() {
      return this.areas.flatMap((area) => area.prefectures)
    },
    filteredPrefs() {
      return this.checkedAreas
        .reduce((filteredPrefs, areaId) => {
          const area = this.areas.find((area) => area.id === areaId)
          if (!area) return this.checkedPrefs

          const areaPrefs = area.prefectures.map((pref) => pref.id)
          const checkedAllPrefs = areaPrefs.every((prefId) => this.checkedPrefs.includes(prefId))

          return checkedAllPrefs ? filteredPrefs.filter((prefId) => !areaPrefs.includes(prefId)) : filteredPrefs
        }, this.checkedPrefs)
        .sort()
    },
    areaLabel() {
      if (this.checkedPrefs.length < 1 && this.checkedAreas.length < 1) {
        return null
      }
      let names = []
      if (this.checkedAreas.length > 0) {
        this.checkedAreas.forEach((areaId) => {
          const area = this.areas.find((area) => area.id === areaId)
          names.push(area.name)
        })
      }
      if (this.checkedPrefs.length > 0) {
        this.filteredPrefs.forEach((prefId) => {
          const pref = this.prefectures.find((pref) => pref.id === prefId)
          names.push(pref.name)
        })
      }
      return names.join('、')
    },
    detailLabel() {
      let labels = []

      if (!!this.checkedRoomKinds && this.checkedRoomKinds.length > 0) {
        let label = []
        if (this.checkedRoomKinds.includes('private')) {
          label.push('個室')
        }
        if (this.checkedRoomKinds.includes('dormitory')) {
          label.push('ドミトリー')
        }
        labels.push(label.join('・'))
      }

      if (!!this.checkedHomeKinds && this.checkedHomeKinds.length > 0) {
        let label = []
        if (this.checkedHomeKinds.includes('detached_house')) {
          label.push('戸建て')
        }
        if (this.checkedHomeKinds.includes('hotel')) {
          label.push('ホテル/旅館')
        }
        if (this.checkedHomeKinds.includes('guest_house')) {
          label.push('ゲストハウス')
        }
        if (this.checkedHomeKinds.includes('share_house')) {
          label.push('シェアハウス')
        }
        if (this.checkedHomeKinds.includes('camp_site')) {
          label.push('キャンプサイト')
        }
        labels.push(label.join('・'))
      }

      if (!!this.checkedHomeGroupKinds && this.checkedHomeGroupKinds.length > 0) {
        let label = []
        if (this.checkedHomeGroupKinds.includes('unlimited')) {
          label.push('予約制限なし')
        }
        if (this.checkedHomeGroupKinds.includes('limited')) {
          label.push('予約制限あり')
        }
        labels.push(label.join('・'))
      }

      if (this.hasAutoApproval) {
        labels.push('予約承認が不要な家')
      }
      if (this.isOriginal) {
        labels.push('オリジナル')
      }
      if (this.canMeetYamori) {
        labels.push('家守に会える')
      }
      if (this.isMemberOnly) {
        labels.push('会員専用')
      }
      if (this.hasDeskChair) {
        labels.push('デスク・チェアあり')
      }
      if (this.hasKitchen) {
        labels.push('キッチンあり')
      }

      if (this.hasDiscount || this.hasDiscountThreeNights || this.hasDiscountSevenNights) {
        let label = ['連泊割']
        if (this.hasDiscountThreeNights) {
          label.push('3泊割')
        }
        if (this.hasDiscountSevenNights) {
          label.push('7泊割')
        }
        labels.push(label.join('・'))
      }

      if (this.favoriteOnly) {
        labels.push('お気に入りのみ')
      }

      if (this.hasParking || this.isParkingFree || this.isParkingReservable) {
        let label = ['駐車場あり']
        if (this.isParkingFree) {
          label.push('無料')
        }
        if (this.isParkingReservable) {
          label.push('事前予約可')
        }
        labels.push(label.join('・'))
      }

      return labels.join('、')
    },
  },
  created() {
    const areas = this.sessionParams.areas ? this.sessionParams.areas : []
    const prefs = this.sessionParams.prefectures ? this.sessionParams.prefectures : []

    this.checkedAreas = areas.map((areaId) => Number(areaId))

    this.checkedPrefs = this.checkedAreas
      .flatMap((areaId) => this.findArea(areaId).prefectures.map((pref) => pref.id))
      .concat(prefs.map((prefId) => Number(prefId)))
  },
  mounted() {
    this.sortedTags = [
      ...this.tags.filter((tag) => tag.id === this.checkedTag),
      ...this.tags.filter((tag) => tag.id !== this.checkedTag),
    ]
  },
  methods: {
    search() {
      const url = new URL(window.location)
      let pathName = '/homes'
      let searchParams = {}

      this.checkedPrefs = this.filteredPrefs
      if (this.checkedAreas.length === 1 && this.checkedPrefs.length === 1) {
        const prefId = this.checkedPrefs[0]
        const areaId = this.findAreaIdByPrefId(prefId)
        if (!!areaId && areaId === this.checkedAreas[0]) {
          pathName += `/areas/${this.checkedAreas[0]}/prefs/${prefId}`
        }
      } else if (this.checkedAreas.length === 1 && this.checkedPrefs.length === 0) {
        pathName += `/areas/${this.checkedAreas[0]}`
      } else if (this.checkedPrefs.length === 1 && this.checkedAreas.length === 0) {
        const prefId = this.checkedPrefs[0]
        const areaId = this.findAreaIdByPrefId(prefId)
        if (areaId) {
          pathName += `/areas/${areaId}/prefs/${prefId}`
        }
      }
      if (pathName === '/homes') {
        pathName += '/search'
        if (this.checkedAreas.length > 0) {
          searchParams.areas = this.checkedAreas
        }
        if (this.checkedPrefs.length > 0) {
          searchParams.prefs = this.checkedPrefs
        }
      }

      if (this.checkedTag) {
        searchParams.tags = [this.checkedTag] // 現状だと選択は単数という前提
      }

      if (this.checkedThemeList) {
        searchParams.theme_list = this.checkedThemeList.obfuscated_id
      }

      if (this.checkInDate && this.checkOutDate) {
        searchParams.check_in_date = this.checkInDate
        searchParams.check_out_date = this.checkOutDate
      }

      searchParams.kinds = !!this.checkedHomeKinds && this.checkedHomeKinds.length > 0 ? this.checkedHomeKinds : null
      searchParams.room_kinds = !!this.checkedRoomKinds && this.checkedRoomKinds.length > 0 ? this.checkedRoomKinds : null
      searchParams.group_kinds =
        !!this.checkedHomeGroupKinds && this.checkedHomeGroupKinds.length > 0 ? this.checkedHomeGroupKinds : null

      searchParams.adult = this.adults
      searchParams.child = this.children
      searchParams.preschooler = this.preschoolers
      searchParams.room_count = this.rooms

      searchParams.original = this.isOriginal
      searchParams.kitchen = this.hasKitchen
      searchParams.parking = this.hasParking
      searchParams.parking_free = this.isParkingFree
      searchParams.parking_prebooking = this.isParkingReservable
      searchParams.desk_chair = this.hasDeskChair
      searchParams.yamori_interaction = this.canMeetYamori
      searchParams.member_only = this.isMemberOnly
      searchParams.is_auto_approved = this.hasAutoApproval
      searchParams.discount = this.hasDiscount
      searchParams.discount_3_nights = this.hasDiscountThreeNights
      searchParams.discount_7_nights = this.hasDiscountSevenNights
      searchParams.favorite = this.favoriteOnly

      url.pathname = pathName
      url.search = ''
      for (const [key, val] of Object.entries(searchParams)) {
        if (val) {
          url.searchParams.append(`q[${key}]`, val)
        }
      }
      window.location.href = url.href
    },
    handleUpdatedDates(checkInDate, checkOutDate) {
      this.checkInDate = checkInDate
      this.checkOutDate = checkOutDate
    },
    handleUpdatedCapacity(key, value) {
      this[key] = value
    },
    handleAreaChanged(isSelected, targetArea) {
      if (isSelected) {
        if (!this.checkedAreas.includes(targetArea.id)) {
          this.checkedAreas.push(targetArea.id)
        }
        targetArea.prefectures.forEach((pref) => {
          if (!this.checkedPrefs.includes(pref.id)) {
            this.checkedPrefs.push(pref.id)
          }
        })
      } else {
        this.checkedAreas = this.removeItem(this.checkedAreas, targetArea.id)
        targetArea.prefectures.forEach((pref) => {
          this.checkedPrefs = this.removeItem(this.checkedPrefs, pref.id)
        })
      }
    },
    handlePrefChanged(isSelected, targetPref) {
      if (isSelected) {
        if (!this.checkedPrefs.includes(targetPref.id)) {
          this.checkedPrefs.push(targetPref.id)
        }
        this.areas.forEach((area) => {
          if (this.areAllPrefsChecked(area, this.checkedPrefs) && !this.checkedAreas.includes(area.id)) {
            this.checkedAreas.push(area.id)
          }
        })
      } else {
        this.checkedPrefs = this.removeItem(this.checkedPrefs, targetPref.id)
        this.areas.forEach((area) => {
          if (!this.areAllPrefsChecked(area, this.checkedPrefs)) {
            this.checkedAreas = this.removeItem(this.checkedAreas, area.id)
          }
        })
      }
    },
    handleClearArea() {
      this.checkedAreas = []
      this.checkedPrefs = []
    },
    findArea(areaId) {
      return this.areas.find((area) => area.id === areaId)
    },
    findAreaIdByPrefId(prefId) {
      return this.areas.find((area) => area.prefectures.some((prefecture) => prefecture.id === prefId)).id
    },
    areAllPrefsChecked(areaObj, checkedPrefIds) {
      return areaObj.prefectures.every((pref) => checkedPrefIds.includes(pref.id))
    },
    handleCheckChanged(key, isChecked) {
      this[key] = isChecked
    },
    handleHomeKindsChanged(isSelected, targetKind) {
      if (isSelected) {
        if (!this.checkedHomeKinds.includes(targetKind)) {
          this.checkedHomeKinds.push(targetKind)
        }
      } else {
        this.checkedHomeKinds = this.removeItem(this.checkedHomeKinds, targetKind)
      }
    },
    handleRoomKindsChanged(isSelected, targetKind) {
      if (isSelected) {
        if (!this.checkedRoomKinds.includes(targetKind)) {
          this.checkedRoomKinds.push(targetKind)
        }
      } else {
        this.checkedRoomKinds = this.removeItem(this.checkedRoomKinds, targetKind)
      }
    },
    handleHasParkingChanged(isChecked) {
      this.hasParking = isChecked

      if (!isChecked) {
        this.isParkingFree = false
        this.isParkingReservable = false
      }
    },
    handleParkingDetailChanged(event) {
      const id = event.target.id
      const isChecked = event.target.checked

      if (id === 'is_parking_free') {
        this.isParkingFree = isChecked
      } else if (id === 'is_parking_reservable') {
        this.isParkingReservable = isChecked
      }

      if (isChecked) {
        this.hasParking = true
      }
    },
    handleHomeGroupKindsChanged(isSelected, targetKind) {
      if (isSelected) {
        if (!this.checkedHomeGroupKinds.includes(targetKind)) {
          this.checkedHomeGroupKinds.push(targetKind)
        }
      } else {
        this.checkedHomeGroupKinds = this.removeItem(this.checkedHomeGroupKinds, targetKind)
      }
    },
    handleHasDiscountChanged(isChecked) {
      this.hasDiscount = isChecked

      if (!isChecked) {
        this.hasDiscountThreeNights = false
        this.hasDiscountSevenNights = false
      }
    },
    handleDiscountDetailChanged(event) {
      const id = event.target.id
      const isChecked = event.target.checked

      if (id === 'has_discount_three_nights') {
        this.hasDiscountThreeNights = isChecked
      } else if (id === 'has_discount_seven_nights') {
        this.hasDiscountSevenNights = isChecked
      }

      if (isChecked) {
        this.hasDiscount = true
      }
    },
    handleClearDetail() {
      this.checkedHomeKinds = []
      this.checkedRoomKinds = []
      this.isOriginal = false
      this.hasKitchen = false
      this.hasParking = false
      this.isParkingFree = false
      this.isParkingReservable = false
      this.hasDeskChair = false
      this.canMeetYamori = false
      this.isMemberOnly = false
      this.checkedHomeGroupKinds = []
      this.hasAutoApproval = false
      this.hasDiscount = false
      this.hasDiscountThreeNights = false
      this.hasDiscountSevenNights = false
      this.favoriteOnly = false
    },
    changeTag(targetTagId) {
      const unchecked = this.checkedTag === targetTagId

      if (unchecked) {
        this.checkedTag = null
      } else {
        this.checkedTag = targetTagId
        this.clearThemeList()
      }
    },
    clearTag() {
      this.checkedTag = null
    },
    themeListLabel() {
      const maxLength = 15
      const title = this.themeList.title

      return title.length > maxLength ? title.slice(0, maxLength) + '...' : title
    },
    changeThemeList() {
      if (this.checkedThemeList) {
        this.checkedThemeList = null
      } else {
        this.checkedThemeList = this.sessionParams.theme_list_id
        this.clearTag()
      }
    },
    clearThemeList() {
      this.checkedThemeList = null
    },
    removeItem(array, item) {
      return array.filter((arrItem) => {
        return arrItem !== item
      })
    },
  },
}
</script>
