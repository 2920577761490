<template>
  <modal
    name="date-select"
    height="100%"
    scrollable
    style="z-index: 1050; display: flex; align-items: flex-start; justify-content: center; height: 100%"
  >
    <div class="modal-search-date">
      <div class="modal-header">
        <span class="modal-header-title">{{ selectLabel }}</span>
      </div>
      <div class="modal-search-date-body">
        <DateCalendarPicker
          ref="dateCalendarPicker"
          :start-date-value="selectedCheckInDate"
          :end-date-value="selectedCheckOutDate"
          :start-date="startDate"
          :end-date="endDate"
          :disabled-dates="disableDates"
          always-visible
          @check-in-changed="handleCheckInChanged"
          @check-out-changed="handleCheckOutChanged"
        />
      </div>
      <div class="modal-fixbottom">
        <button class="modal-clearbtn" @click.prevent="clearSelection">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#adadad"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline-block mb-1"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          選択をクリア
        </button>
      </div>
    </div>
    <button type="button" class="modal-closebtn" @click="$modal.hide('date-select')"></button>
  </modal>
</template>
<script>
import DateCalendarPicker from 'vuejs/shared/components/DateCalendarPicker'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  components: {
    DateCalendarPicker,
  },
  props: {
    currentTime: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    checkInDate: {
      type: String,
      default: null,
    },
    checkOutDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedCheckInDate: this.checkInDate,
      selectedCheckOutDate: this.checkOutDate,
    }
  },
  computed: {
    startDateObject() {
      return dayjs(this.startDate)
    },
    shouldIncludeToday() {
      const current = dayjs(this.currentTime)
      const noon = dayjs(current.format('YYYY-MM-DD') + 'T12:00:00')
      return !current.isAfter(noon)
    },
    disableDates() {
      if (this.shouldIncludeToday) {
        return []
      } else {
        return [this.startDateObject.format('YYYY/MM/DD')]
      }
    },
    selectLabel() {
      const selectedBoth = this.selectedCheckInDate && this.selectedCheckOutDate
      const selectedOnlyCheckIn = this.selectedCheckInDate && !this.selectedCheckOutDate

      if (selectedBoth) {
        return '日付を選択'
      } else if (selectedOnlyCheckIn) {
        return '出発日を選択してください'
      } else {
        return '到着日を選択してください'
      }
    },
  },
  methods: {
    handleCheckInChanged(date) {
      if (date) {
        this.selectedCheckInDate = date.format('YYYY-MM-DD')
      } else {
        this.selectedCheckInDate = null
      }
      this.selectedCheckOutDate = null
    },
    handleCheckOutChanged(date) {
      if (date) {
        this.selectedCheckOutDate = date.format('YYYY-MM-DD')
        this.$emit('updateValue', this.selectedCheckInDate, this.selectedCheckOutDate)
        this.$modal.hide('date-select')
      }
    },
    clearSelection() {
      this.selectedCheckInDate = null
      this.selectedCheckOutDate = null
      this.$emit('updateValue', null, null)
      if (this.$refs.dateCalendarPicker) {
        this.$refs.dateCalendarPicker.clearSelection()
      }
    },
  },
}
</script>
