<template>
  <modal
    name="select-area"
    height="100%"
    classes="modal-wrapper"
    scrollable
    style="z-index: 1050; display: flex; justify-content: center"
  >
    <div class="modal-search-area">
      <div class="modal-search-area-box">
        <div v-for="area in areas" :key="'area-' + area.id" style="margin-bottom: 2rem">
          <div class="modal-search-area-region">
            <input
              :id="'area_' + area.id"
              type="checkbox"
              :checked="checkedAreas.includes(area.id)"
              @change="areaChanged($event, area)"
            />
            <label :for="'area_' + area.id">{{ area.name + 'エリア' }}</label>
          </div>
          <div class="modal-search-area-pref">
            <div v-for="pref in area.prefectures" :key="'pref-' + pref.id">
              <input
                :id="'pref_' + pref.id"
                type="checkbox"
                :checked="checkedPrefs.includes(pref.id)"
                @change="prefChanged($event, pref)"
              />
              <label :for="'pref_' + pref.id">{{ pref.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-fixbottom">
      <button class="modal-clearbtn" @click="clearArea">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#adadad"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="inline-block mb-1"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
        選択をクリア
      </button>
      <button class="modal-primarybtn" @click.prevent="$modal.hide('select-area')">決定</button>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    areas: {
      type: Array,
      required: true,
    },
    checkedAreas: {
      type: Array,
      required: true,
    },
    checkedPrefs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    areaChanged(event, area) {
      this.$emit('areaChanged', event.target.checked, area)
    },
    prefChanged(event, pref) {
      this.$emit('prefChanged', event.target.checked, pref)
    },
    clearArea() {
      this.$emit('clearArea')
    },
  },
}
</script>
