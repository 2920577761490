<template>
  <modal name="capacity-select" height="100%" scrollable style="z-index: 1050; display: flex; justify-content: center">
    <div class="modal-search-people">
      <div class="modal-search-people-box">
        <div class="modal-search-people-txt">
          <p class="modal-search-people-txt-ttl">大人</p>
          <p class="modal-search-people-txt-sub">中学生以上</p>
        </div>
        <IntegerCounter
          :value="adults"
          :min-value="1"
          :max-value="6"
          form-key="adults"
          @updateValue="$listeners['updateValue']"
        />
      </div>
      <div class="modal-search-people-box">
        <div class="modal-search-people-txt">
          <p class="modal-search-people-txt-ttl">子ども</p>
          <p class="modal-search-people-txt-sub">小学生</p>
        </div>
        <IntegerCounter
          :value="children"
          :min-value="0"
          :max-value="6"
          form-key="children"
          @updateValue="$listeners['updateValue']"
        />
      </div>
      <div class="modal-search-people-box">
        <div class="modal-search-people-txt">
          <p class="modal-search-people-txt-ttl">幼児</p>
          <p class="modal-search-people-txt-sub">未就学児</p>
        </div>
        <IntegerCounter
          :value="preschoolers"
          :min-value="0"
          :max-value="6"
          form-key="preschoolers"
          @updateValue="$listeners['updateValue']"
        />
      </div>
      <div v-if="!isLegacyPlan">
        <div class="modal-search-people-room">
          <div class="modal-search-people-txt">
            <p class="modal-search-people-txt-ttl">部屋数</p>
          </div>
          <IntegerCounter
            :value="rooms"
            :min-value="1"
            :max-value="2"
            form-key="rooms"
            @updateValue="$listeners['updateValue']"
          />
        </div>
        <div class="modal-search-people-notice">
          <a href="https://address-membersupport.zendesk.com/hc/ja/articles/20051109977369" target="_blank" rel="noopener">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 28 28"
              fill="none"
              stroke="#CF761D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              style="margin-top: 3px"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
            個室の定員について
          </a>
        </div>
      </div>
    </div>
    <div class="modal-fixbottom">
      <button class="modal-primarybtn" @click.prevent="$modal.hide('capacity-select')">決定</button>
    </div>
  </modal>
</template>

<script>
import IntegerCounter from './IntegerCounter'

export default {
  components: {
    IntegerCounter,
  },
  props: {
    adults: {
      type: Number,
      default: 0,
    },
    children: {
      type: Number,
      default: 0,
    },
    preschoolers: {
      type: Number,
      default: 0,
    },
    rooms: {
      type: Number,
      default: 0,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
