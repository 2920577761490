<template>
  <div class="modal-search-people-btn">
    <button :class="{ 'btn-disabled': !canDecrement }" @click="decrement">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#AC9890"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </button>
    <div class="modal-search-people-btn-no">{{ value }}</div>
    <button :class="{ 'btn-disabled': !canIncrement }" @click="increment">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#AC9890"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="16"></line>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    formKey: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: -Infinity,
    },
    maxValue: {
      type: Number,
      default: Infinity,
    },
  },
  computed: {
    canIncrement() {
      return this.value < this.maxValue
    },
    canDecrement() {
      return this.value > this.minValue
    },
  },
  methods: {
    increment() {
      if (this.canIncrement) {
        this.$emit('updateValue', this.formKey, this.value + 1)
      }
    },
    decrement() {
      if (this.canDecrement) {
        this.$emit('updateValue', this.formKey, this.value - 1)
      }
    },
  },
}
</script>
