<template>
  <div class="bg-white rounded-xl absolute left-4 right-4 md:static top-[170px] md:top-0 max-w-md">
    <div class="p-4 md:px-6 md:pt-6">
      <button
        class="border border-bordergray rounded-lg p-3 w-full"
        data-modal="modal-select-area"
        @click.prevent="$modal.show('select-area')"
      >
        <div class="flex items-center w-full">
          <p class="pr-2">
            <icon-area-pin />
          </p>
          <p class="truncate" :class="{ 'text-gray': !areaLabel }">{{ areaLabel || 'エリアを選択' }}</p>
        </div>
      </button>
      <div class="flex gap-2 mt-2">
        <button
          class="border border-bordergray rounded-lg p-3 flex-1 overflow-hidden"
          @click.prevent="$modal.show('date-select')"
        >
          <div class="flex items-center">
            <p class="pr-2">
              <icon-calendar />
            </p>
            <div class="overflow-hidden">
              <p class="truncate" :class="{ 'text-gray': !datesLabel }">{{ datesLabel || '日付を選択' }}</p>
            </div>
          </div>
        </button>
        <button
          class="border border-bordergray rounded-lg p-3 flex-1 overflow-hidden"
          data-modal="modal-capacity-select"
          @click.prevent="$modal.show('capacity-select')"
        >
          <div class="flex items-center">
            <p class="pr-2">
              <icon-people />
            </p>
            <div class="overflow-hidden">
              <p class="truncate">{{ capacityLabel }}</p>
            </div>
          </div>
        </button>
      </div>
      <div class="flex items-center mt-3">
        <button
          class="text-brown underline font-bold flex items-center whitespace-nowrap"
          data-modal="modal-detail-select"
          @click.prevent="$modal.show('detail-select')"
        >
          <icon-setting />
          絞り込み
        </button>
        <p class="truncate text-sm ml-3">{{ detailLabel }}</p>
      </div>
    </div>
    <div class="flex md:flex-wrap gap-2 whitespace-nowrap overflow-x-scroll md:overflow-auto px-4 md:px-6">
      <div
        v-for="tag in sortedTags"
        :key="`tag-${tag.id}`"
        class="top-search-tag cursor-pointer"
        :class="{ checked: checkedTag === tag.id }"
        @click="changeTag(tag.id)"
      >
        {{ tag.name }}
      </div>
    </div>
    <div class="p-4 md:p-6">
      <button class="btn btn-orange w-full" @click="search">ADDressの家を探す</button>
    </div>
    <Teleport to="body">
      <DateSelectModal
        :current-time="currentTime"
        :start-date="calendarStartDate"
        :end-date="calendarEndDate"
        :check-in-date="checkInDate"
        :check-out-date="checkOutDate"
        @updateValue="handleUpdatedDates"
      ></DateSelectModal>
      <AreaSelectModal
        :areas="areas"
        :checked-areas="checkedAreas"
        :checked-prefs="checkedPrefs"
        @areaChanged="handleAreaChanged"
        @prefChanged="handlePrefChanged"
        @clearArea="handleClearArea"
      ></AreaSelectModal>
      <CapacitySelectModal
        :adults="adults"
        :children="children"
        :preschoolers="preschoolers"
        :rooms="rooms"
        :is-legacy-plan="isLegacyPlan"
        @updateValue="handleUpdatedCapacity"
      ></CapacitySelectModal>
      <DetailSelectModal
        :home-kinds="homeKinds"
        :room-kinds="roomKinds"
        :home-group-kinds="homeGroupKinds"
        :checked-home-kinds="checkedHomeKinds"
        :checked-room-kinds="checkedRoomKinds"
        :is-original="isOriginal"
        :has-kitchen="hasKitchen"
        :has-parking="hasParking"
        :is-parking-free="isParkingFree"
        :is-parking-reservable="isParkingReservable"
        :has-desk-chair="hasDeskChair"
        :can-meet-yamori="canMeetYamori"
        :is-member-only="isMemberOnly"
        :checked-home-group-kinds="checkedHomeGroupKinds"
        :has-auto-approval="hasAutoApproval"
        :has-discount="hasDiscount"
        :has-discount-three-nights="hasDiscountThreeNights"
        :has-discount-seven-nights="hasDiscountSevenNights"
        :favorite-only="favoriteOnly"
        :is-contracted="isContracted"
        :is-legacy-plan="isLegacyPlan"
        :is-unregistered="isUnregistered"
        :icon-original-img-src="iconOriginalImgSrc"
        @checkChanged="handleCheckChanged"
        @homeKindsChanged="handleHomeKindsChanged"
        @roomKindsChanged="handleRoomKindsChanged"
        @hasParkingChanged="handleHasParkingChanged"
        @parkingDetailChanged="handleParkingDetailChanged"
        @homeGroupKindsChanged="handleHomeGroupKindsChanged"
        @hasDiscountChanged="handleHasDiscountChanged"
        @discountDetailChanged="handleDiscountDetailChanged"
        @clearDetail="handleClearDetail"
      ></DetailSelectModal>
    </Teleport>
  </div>
</template>

<script>
import DateSelectModal from '../../shared/components/search/DateSelectModal.vue'
import CapacitySelectModal from '../../shared/components/search/CapacitySelectModal.vue'
import AreaSelectModal from '../../shared/components/search/AreaSelectModal.vue'
import DetailSelectModal from '../../shared/components/search/DetailSelectModal.vue'

import IconAreaPin from '../../shared/icons/AreaPin.vue'
import IconCalendar from '../../shared/icons/CalendarBig.vue'
import IconPeople from '../../shared/icons/People.vue'
import IconSetting from '../../shared/icons/Setting.vue'

import Teleport from 'vue2-teleport'

import SearchBase from '../../shared/components/search/SearchBase.vue'

export default {
  components: {
    DateSelectModal,
    CapacitySelectModal,
    AreaSelectModal,
    DetailSelectModal,
    IconAreaPin,
    IconCalendar,
    IconPeople,
    IconSetting,
    Teleport,
  },
  extends: SearchBase,
}
</script>
